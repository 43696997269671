import React from 'react';
import Box from 'components/box';
import Head from 'components/head';
import Layout from 'components/layout';

const NotFound = () => (
  <Layout>
    <Head pageTitle="Page not found." />
    <Box>Not found.</Box>
  </Layout>
);

export default NotFound;
